
import * as _ from "lodash";
import Swal from 'sweetalert2'
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex'
import StudyCreationBase from '@/components/StudyCreation/StudyCreationBase.vue'
import ViabilityElement from '@/components/StudyCreation/ViabilityElement.vue'
import Separation from '@/components/StudyCreation/Separation.vue'
import Input from '~/components/Input/Input.vue'
import PriceStep4 from '@/components/StudyCreation/PriceStep4.vue'
import Button from '@/components/Button.vue'
import store from '@/store'
import { ProductTypes, FocusSubProductTypes, userOrigin } from '@/utils/constants.ts'
import moment from 'moment-timezone';

import UsersConfig from "./UsersConfig.vue";
export default defineComponent({
  name : 'Step4Survey',
  components:{
    StudyCreationBase,
    ViabilityElement,
    Separation,
    Input,
    PriceStep4,
    Button
  },
  computed: {
    ...mapGetters([
      'clientCreditsPlan',
      'surveySteps',
      'watFocusSteps',
      'countriesAvailable',
      'languages'
    ]),
    quotasLength(){
      if(this.viability.quotas.length == 0) return 0
      return this.viability.quotas[0].selectionBlocks.length
    },
    criteriaLength(){
      return this.isWatFocus() ? this.watFocus.individualCriteria.length : this.viability.individualCriteria.length
    },
    viabilityColor(){
      let icons = [this.viability.criteria.icon]
      const criteria = this.viability.criteria
      const quotas = this.viability.quotas
      if(quotas != undefined && quotas.length > 0){
        for(let q of quotas){
          if(criteria.genderCriteria && q.selectionBlocks[0]?.genderCriteria) icons.push(q.icon)
          if(criteria.ageCriteria && q.selectionBlocks[0]?.ageCriteria) icons.push(q.icon)
          if(criteria.provinceCriteria && q.selectionBlocks[0]?.provinceCriteria) icons.push(q.icon)
          if(criteria.regionCriteria && q.selectionBlocks[0]?.regionCriteria) icons.push(q.icon)
          if(criteria.nielsenCriteria && q.selectionBlocks[0]?.nielsenCriteria) icons.push(q.icon)
          if(criteria.categoryCriteria && q.selectionBlocks[0]?.categoryCriteria && q.selectionBlocks[0].categoryCriteria[0].categoryId == criteria.categoryCriteria[0].categoryId && q.selectionBlocks[0].categoryCriteria[0].questionId == criteria.categoryCriteria[0].questionId) icons.push(q.icon)
        }
      }
      if(icons.includes('RED')) return 'RED'
      else if(icons.includes('AMBER')) return 'AMBER'
      else return 'GREEN'
    },
    minPublicationDate(){
      let date = new Date()
      const year = date.getFullYear()
      let month = (date.getMonth()+1).toString()
      let day = (date.getDate() + 1).toString()
      if(day.length < 2) day = "0" + day
      if(month.length < 2) month = "0" + month
      return  year + "-" + month + "-" + day
    },
    countryValue(){
      let vm = this
			const countries: any = this.countriesAvailable || []
      const country = countries.filter((country: any) => country != undefined && country.secondaryValue.toLowerCase() == vm.viability.country.toLowerCase())[0]
      if(country == undefined) return ""
      return this.$t(country.value)
    },
    language(){
      let vm = this
      const languages: any = this.languages
      const language = languages.filter((lang: any) => lang != undefined && lang.secondaryValue.toLowerCase() == vm.watFocus.language.toLowerCase())[0]
      if(language == undefined) return ""
      return this.$t(language.value)
    },
    focusStudyType(){
      return FocusSubProductTypes.GROUP == this.watFocus.type ? this.$t('suite_watfocus_type_group') : this.$t('suite_watfocus_type_interview')
    },
    focusUserOrigin(){
      return userOrigin.WAT == this.watFocus.usersOrigin ? 'WAT Community' : this.$t('suite_watlab_step3_origin_own2')
    },
    isFocusUsersOriginWAT(){
      return userOrigin.WAT == this.watFocus.usersOrigin
    },
    isWatFocusInterview(){
      return FocusSubProductTypes.INTERVIEW == this.watFocus.type
    },
    step3Route(){
      if(this.isWatSurvey()) return 'newstudy.step3Survey'
      if(this.isWatUxQuanti()) return 'newstudy.step3WatUxQuanti'
      if(this.isClickTest()) return 'newstudy.step3ClickTest'
      if(this.isWatUx()) return 'newstudy.step3WatUx'
      if(this.isWatFocus()) return 'newstudy.step4WatFocus'
    },
    steps(){
      return this.isWatFocus() ? this.watFocusSteps : this.surveySteps
    }
  },
  props:{
    STEP:[String, Number],
    type:String,
    clientCountry:String
  },
  data(){
    return{
      testName: '',
      errorMessage:this.$t('suite_survey_step3_error'),
      error: false,
      dataIsUpdated: false,
      viability:{
        individualCriteria:[],
        criteria:{icon:'',genderCriteria:{},ageCriteria:{},provinceCriteria:{},regionCriteria:{},nielsenCriteria:{},categoryCriteria:[{categoryId:0,questionId:0}]},
        quotas:[{icon:'',selectionBlocks:[{genderCriteria:{},ageCriteria:{},provinceCriteria:{},regionCriteria:{},nielsenCriteria:{},categoryCriteria:[{categoryId:0,questionId:0}]}]}],
        country: this.clientCountry || 'es'
      },
      test:{},
      testTasks:[{questions:[]}],
      categories:[],
      publicationDate:'',
      finishDate:'',
      watFocus:{
        country:'',
        language: '',
        type:'',
        sessionNum:0,
        testerNum:0,
        usersOrigin:'',
        sessionMinutes:0,
        criteria:{},
        individualCriteria:[]
      }
    }
  },
  methods:{
    async fetchViability(){
      await store.dispatch(this.getViabilityFetchServiceName(),{contractId:this.$route.params.id}).then((data) => {
        this.viability = data
      })
    },
    async fetchWatFocusConfig(){
      await store.dispatch('fetchWatFocusConfiguration',{contractId:this.$route.params.id}).then((data) => {
        this.watFocus = data
        this.publicationDate = moment(data.publicationDate).format('YYYY-MM-DD');
        this.finishDate = moment(data.finishDate).format('YYYY-MM-DD');
        this.viability.country = data.country
      })
    },
    async fetchTest(){
      await store.dispatch('getTest',{testId:this.$route.params.testId}).then((data) => {
        this.test = data.test
        this.testTasks = data.testTasks
      })
    },
    async fetchCriteria(){
      await store.dispatch('fetchCriteria', { country: this.viability.country, lang: this.clientCountry })
      .then((data) => {
        data = data.map(function(category: any){
          category.categoryName = category.categoryName.translated
          category.clientName = category.clientName.translated
          category.questions = category.questions.map(function(question: any){
            question.clientText = question.clientText.translated
            if(question.values){
              question.values = question.values.map(function(value: any){
                value.value = value.value.translated
                return value
              })
            }
            return question
          })
          return category
        })
        this.categories = data
      })
    },
    async step4Confirmation(){
      this.error = false
      if(this.publicationDate.length == 0 || (this.isWatFocus() && this.finishDate.length == 0)) {
        this.errorMessage = this.$t('suite_survey_step4_error_publicationdate')
        this.error = true
        return
      }
      const data = {
        publicationDate: this.publicationDate,
        finishDate: this.isWatFocus() ? this.finishDate : null
      }
      let vm = this
      await store.dispatch(this.getConfirmationServiceName(),{contractId:this.$route.params.id, data:data}).then((data) => {
        Swal.fire({
          title:vm.$t('suite_swal_proccess_completed'),
          icon:"success",
          customClass:{popup:"swal-customSuccess"}
        })
        this.$router.push({name:'dashboard'})
      })
      .catch((response)  => {
        if(response.payload == "Publication date not valid, must wait 48 hours" ||
          response.payload == "date_not_valid"
        ){
          Swal.fire({
            title:vm.$t('suite_swal_error_publication_date'),
            icon:"warning",
            customClass:{popup:"swal-customWarning"}
          })
        }
        else{
          Swal.fire({
            title:vm.$t('suite_swal_not_enough_credits'),
            icon:"warning",
            customClass:{popup:"swal-customWarning"}
          })
        }
      })
    },
    getViabilityFetchServiceName(){
      if(this.isWatSurvey()) return 'fetchWatSurveyViability'
      if(this.isWatUxQuanti()) return 'fetchWatUxQuantiViability'
      if(this.isClickTest()) return 'fetchClickTestViability'
      if(this.isWatUx()) return 'fetchWatUxViability'
      else return '' 
    },
    getConfirmationServiceName(){
      if(this.isWatSurvey()) return 'confirmationWatSurvey'
      if(this.isWatUxQuanti()) return 'confirmationWatUxQuanti'
      if(this.isClickTest()) return 'confirmationClickTest'
      if(this.isWatUx()) return 'confirmationWatUx'
      if(this.isWatFocus()) return 'confirmationWatFocus'
      else return '' 
    },
    isWatFocus(){
      return this.type == ProductTypes.WAT_FOCUS
    },
    isWatSurvey(){
      return this.type == ProductTypes.WAT_SURVEY
    },
    isWatUx(){
      return this.type == ProductTypes.WAT_UX
    },
    isWatUxQuanti(){
      return this.type == ProductTypes.WAT_UX_QUANTI
    },
    isClickTest(){
      return this.type == ProductTypes.CLICK_TEST
    }
  },
  async mounted(){
    $("html, body").animate({ scrollTop: 0 }, 400);
    if(this.isWatFocus()){
      this.fetchWatFocusConfig()
    }
    else {
      await this.fetchCriteria()
      await this.fetchViability()
      await this.fetchTest()
    }
  }
})
